<template>
    <AppPaginatedGridNested
        ref="grid"
        :service="service"
        :parentName="parentName"
        :queryParams="queryParams"
        :defaultProps="queryParams"
        :expander="false"
        :labelBotaoAdicionarItem="labelBotaoAdicionarItem"
        @onAfterSave="onAfterSave"
        @onAfterLoad="onAfterLoad"
        nomeTelaDoManual="tipo_exames-list"
    >
        <template #header>
            <h3>{{ `Exames` }}</h3>
        </template>
        <template #columns>
            <Column field="tipoExame" header="Tipo exame">
                <template #editor="{ data, field }">
                    <Dropdown
                        v-model="data[field]"
                        @update:modelValue="data.tipoExameId = data[field]?.id"
                        :options="tiposExames"
                        optionLabel="descricao"
                    />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.tipoExame?.descricao }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.createdAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import CrudService from '@/services/crud';

export default {
    emits: ['voltarTabGeral','setSolicitaTipoExameOcupRisco'],
    props: {
        form: {
            type: Object,
            required: true
        },
        queryParams: {
            type: Object,
        },
        service: {
            type: String,
            required: true
        },
        parentName: {
            type: String
        },
        labelBotaoAdicionarItem: {
            type: String
        }
    },
    data() {
        return {
            tiposExames: null,
        }
    },
    mounted() {
        this.$serviceTipoExame = new CrudService('/tipo_exames');
        this.loadTipoExame();
    },
    methods: {
        async loadTipoExame() {
            this.tiposExames = (await this.$serviceTipoExame.findAll({})).data;
        },
        onAfterSave(data) {
            this.$emit('setSolicitaTipoExameOcupRisco', data);
            this.$emit('voltarTabGeral');
            this.$refs.grid.load();
        },
        onAfterLoad(data) {
            this.$emit('setSolicitaTipoExameOcupRisco', data);
        }
    }
}
</script>
